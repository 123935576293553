<template>
  <div>
    <search-form :items="items" @fnSearch="handleFnSearch" />
    <grid :columns="columns" :url="url" :search="search" />
  </div>
</template>

<script>
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Clustering from '@/api/clustering.js';

export default {
  name: 'user',
  components: {
    SearchForm,
    Grid,
  },
  props: ['groupId'],
  data() {
    return {
      items: [
        { key: 'name', label: '姓名' },
        { key: 'email', label: '邮箱' },
      ],
      columns: [
        { dataIndex: 'name', title: '姓名' },
        { dataIndex: 'phone', title: '电话' },
        { dataIndex: 'email', title: '邮箱' },
      ],
      url: Clustering.memberPageUrl,
      search: { groupId: this.groupId },
    };
  },
  watch: {
    groupId(value) {
      this.search = { groupId: value };
    },
  },
  methods: {
    handleFnSearch(value) {
      this.search = Object.assign(value, { groupId: this.groupId });
    },
  },
};
</script>

<style scoped></style>
